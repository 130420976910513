* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Message Styles */

.message {
    margin-bottom: 16px;
    width: 100%;
}

.message__name {
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
}

.message__meta {
    color: #777;
    font-size: 14px;
}

.message a {
    color: #0070cc;
}
.chat__sidebar {
    padding: 10px;
    display: inline-block;
    width: 100%;
    height: 80%;
    color: white;
    /* background: #999; */
    overflow-y: auto;
}
.DIV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1.5px solid #eee;
}
.DIV:hover {
    background: #e4e4e485;
}
.heading-2 {
    padding: 15px;
    /* height: 10vh; */
    font-size: 20px;
    letter-spacing: 2px;
    color: #222;
    text-transform: capitalize;
    background-color: #e4e4e485;
    margin-top: 0;
}
.main {
    display: flex;
    height: calc(100vh - 100px);
}

.video {
    flex: 0 0 70%;
    display: flex;
    background-color: aliceblue;
}

.sidebar {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
}
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #eee;
}
.guest {
    width: 100%;
    height: 47%;
}
.speaker {
    height: 47%;
    margin-top: 10px;
}

#style-5::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
    background-color: #0ae;

    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        color-stop(0.5, rgba(255, 255, 255, 0.2)),
        color-stop(0.5, transparent),
        to(transparent)
    );
}
